import React from 'react'
import PropTypes from 'prop-types'

class ChatAction extends React.Component {
  handleClick = () => {
    const { content, handleAction } = this.props
    handleAction(content.linkType, content.location)
  }

  render () {
    const buttonType = `type-${this.props.identifier}`
    return (
      <button
        onClick={this.handleClick}
        className={`button is-primary is-large ${buttonType}`}
      >
        {this.props.content.text}
      </button>
    )
  }
}

ChatAction.propTypes = {
  content: PropTypes.object,
}

export default ChatAction
