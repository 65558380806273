import React from 'react'
import PropTypes from 'prop-types'
import ChatElement from './ChatElement'
import ChatAvatar from './ChatAvatar'
import ActionContainer from './ActionContainer'
import { navigate } from 'gatsby'
import './styles.sass'

class ChatContainer extends React.Component {
  state = {
    activeKey: 'Hello', // @TODO: Set the first element as activeKey
    conversations: null,
    showActions: false,
    showAnimation: false,
  }

  componentDidMount () {
    // reset local state when component is remounting
    this.setState({showActions: false, showAnimation: false})
    // on component mount, load the conversations into local state
    const {conversations} = this.props
    let conversationsMap
    if (conversations && conversations.length > 0) {
      conversationsMap = conversations.map(conversation => {
        return [
          conversation.node.frontmatter.title,
          conversation,
        ]
      })
    }
    this.setState({conversations: new Map(conversationsMap)})
  }

  handleAction = (linkType, location) => {
    // hide the action bar again
    this.setState({showActions: false})
    // wait a moment to let the user see the button animation
    setTimeout(() => {
      if (linkType === 'chat') {
        this.setState({activeKey: location})
      } else if (linkType === 'external') {
        window.location.href = `${location}`
      } else if (linkType === 'internal') {
        navigate(location)
      }
    }, 400)
  }

  handleTypingDone = () => {
    this.setState({showActions: true, showAnimation: true})
  }

  render () {
    const { conversations, showActions } = this.state
    // render the chat, once the conversations data is prepared in local state
    if (conversations && conversations.size > 0) {
      // get the active conversation based on state value
      const activeConversation = conversations.get(this.state.activeKey)
      const { frontmatter, fields } = activeConversation.node
      return (
        <div className='container chat-container'>
          <div className='columns'>
            <div className='column is-2 is-offset-1'>
              <ChatAvatar
                showAnimation={this.state.showAnimation}
              />
            </div>
            <div className='column is-7 is-offset-1'>
              <ChatElement
                textElements={frontmatter.textelems}
                handleTypingDone={this.handleTypingDone}
                messageKey={fields.slug}
              />
              { showActions && (
                <ActionContainer
                  actions={frontmatter.actions}
                  handleAction={this.handleAction}
                />
              ) }
            </div>
          </div>
        </div>
      )
    } else return null
  }
}

ChatContainer.propTypes = {
  conversations: PropTypes.array,
}

export default ChatContainer
