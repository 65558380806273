import React from 'react'
import Typist from 'react-typist'

class ChatElement extends React.Component {
  render () {
    const {textElements} = this.props
    return (
      <Typist
        key={this.props.messageKey}
        onTypingDone={this.props.handleTypingDone}
        className='chat-element'
      >
        {textElements.elems && textElements.elems.map((textElement, i) => {
          if (textElement.elemType === 'paragraph') {
            return (
              <p key={`elem-${i}`}>
                {textElement.elemValue}
              </p>
            )
          } else if (textElement.elemType === 'span') {
            return (
              <span key={`elem-${i}`}>
                {textElement.elemValue}
              </span>
            )
          } else if (textElement.elemType === 'delay') {
            return (
              <Typist.Delay
                key={`elem-${i}`}
                ms={parseInt(textElement.elemValue, 10)}
              />
            )
          } else if (textElement.elemType === 'backspace') {
            return (
              <Typist.Backspace
                key={`elem-${i}`}
                count={parseInt(textElement.elemValue, 10)}
                delay={200}
              />
            )
          }
        })}
      </Typist>
    )
  }
}

export default ChatElement
