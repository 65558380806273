import React from 'react'
import { CSSTransition } from 'react-transition-group'
import ChatAction from './ChatAction'

const ActionContainer = ({actions, handleAction}) => (
  <div className='action-container'>
    { actions &&
      actions.button &&
      actions.button.map((content, i) =>
        <CSSTransition
          key={`${i + 1}`}
          timeout={5000}
          classNames='chatanimation'
          appear
          in
        >
          <ChatAction
            key={i}
            identifier={i + 1}
            content={content}
            handleAction={handleAction}
          />
        </CSSTransition>
      ) }
  </div>
)

export default ActionContainer
