import React from 'react'
import './styles.sass'

class AnimatedFace extends React.Component {
  render () {
    return (
      <div className='animated-face'>
        {/* Add a photo head here */}
        <img className='face-0-animation' src={'/img/0_b_animation.gif'} alt='timo' />
        { this.props.showAnimation && (
          <img className='face-1-animation' src={'/img/1_b_animation.gif'} alt='timo' />
        ) }

      </div>
    )
  }
}

export default AnimatedFace
