import React from 'react'
import './styles.sass'

class StickFigure extends React.Component {
  render () {
    return (
      <div className='stick-figure'>
        {/* Add a photo head here */}
        <img className='head' src={'/img/timo.jpg'} alt='timo' />
        <div className='body torso' />
        <div className='body leftarm' />
        <div className='body hand' />
        <div className='body rightarm' />
        <div className='body leftleg' />
        <div className='body leftfoot' />
        <div className='body rightleg' />
        <div className='body rightfoot' />
      </div>
    )
  }
}

export default StickFigure
