import React from 'react'
import StickFigure from '../StickFigure'
import AnimatedFace from '../AnimatedFace'

const ChatAvatar = (props) => (
  <div className='chat-avatar'>
    {/* <StickFigure /> */}
    <AnimatedFace
      showAnimation={props.showAnimation}
    />
  </div>
)

export default ChatAvatar
