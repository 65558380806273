import React from 'react'
import PropTypes from 'prop-types'
import Layout from '../components/Layout'
import Chat from '../components/Chat'

const HomePage = ({pageContext}) => {
  console.log('...hello!')
  return (
    <Layout>
      <Chat
        conversations={pageContext.conversations}
      />
    </Layout>
  )
}

HomePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
  pageContext: PropTypes.shape({
    conversations: PropTypes.array,
  }),
}

export default HomePage
